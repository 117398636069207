<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新开通城市</a-button>
        <!-- <a-button type="danger" icon="delete" @click="handleDelete">移除</a-button> -->
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleDelete(record)">移除</a>
          </template>
        </span>
      </s-table>

      <a-modal
        title="移除"
        :visible="del.visible"
        :confirm-loading="del.confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleDeleteCancel"
      >
        <p>{{ del.ModalText }}</p>
      </a-modal>

      <create-form
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        @emitResult="getResult"
        @cancel="handleCancel"
        @ok="handleOk"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryAddr, setOpen } from '@/api/addrs'

import CreateForm from './modules/CreateCityForm'

const columns = [
  {
    title: '城市名称',
    dataIndex: 'name'
  },
  {
    title: '全称',
    dataIndex: 'fullname'
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '关闭'
  },
  1: {
    status: 'processing',
    text: '运行中'
  },
  2: {
    status: 'success',
    text: '已上线'
  },
  3: {
    status: 'error',
    text: '异常'
  }
}

export default {
  name: 'OpenList',
  components: {
    STable,
    Ellipsis,
    CreateForm
  },
  data() {
    this.columns = columns
    return {
      noticeTitle: '开通城市管理',
      totalCount: 0,
      visible: false,
      confirmLoading: false,
      openId: [],
      mdl: null,
      del: {
        ModalText: '您要移除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      advanced: false,
      queryParam: {},
      search: {
        visible: false
      },
      loadData: parameter => {
        const param = {
          level: 'city',
          opened: true
        }
        const params = this.paramFormat(parameter, param, 'openedTime')
        Object.assign(params, this.queryParam)
        return queryAddr(params).then(res => {
          const result = this.resFormat(res)
          result.data = this.emptyChildren(result.data)
          this.totalCount = result.totalCount
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  mounted() {
    this.getUnOpen()
  },
  created() {},
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    reload() {
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    handleAdd() {
      this.mdl = null
      this.visible = true
    },
    handleDelete(record) {
      const len = this.selectedRows.length
      if (record.id) {
        console.log(record, 'record')
        this.mdl = record
        this.del.visible = true
      } else {
        if (len <= 0) {
          this.$message.warning('请选择要移除的项', 2)
        } else {
          this.del.visible = true
        }
      }
    },
    handleDeleteCancel(e) {
      this.$message.warning('取消了移除操作', 2)
      this.del.visible = false
    },
    handleEdit(record) {
      this.visible = true
      this.mdl = { ...record }
    },
    getResult(data) {
      this.openId = data
    },
    handleOk() {
      this.confirmLoading = true
      const { openId } = this
      if (openId === []) {
        this.$notification.error({
          message: this.noticeTitle,
          description: '请先选择未开通城市'
        })
      } else {
        const openArr = openId
        this.setOpened(openArr, true)
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要移除这些项目吗'
        if (this.del.visible) {
          console.log(this.selectedRows, 'rows')
          const { selectedRows, mdl } = this
          if (selectedRows.length === 0) {
            const list = [mdl.id]
            this.setOpened(list, false, '移除')
          } else {
            const list = this.selectedRows.map(item => {
              return item.id
            })
            this.setOpened(list, false, '移除')
          }
        }
      }, 2000)
    },
    handleCancel() {
      this.visible = false
      this.$refs.createModal.clear()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    /**
     * FindCitys
     * $emit
     */
    getUnOpen() {
      this.confirmLoading = true
      const params = {
        page: 0,
        size: 1000,
        level: 'city',
        opened: false
      }
      queryAddr(params)
        .then(res => {
          this.confirmLoading = false
          const data = res.data.content || []
          this.$store.commit('SET_OPEN', data)
          console.log('更新 un opened');
        })
    },
    getValue(data) {
      this.queryParam.keyword = data
    },
    /**
     * @param arr [id1, id2, id3]
     * @param opened ture/false
     */
    setOpened(arr, opened, msg = '开通') {
      setOpen(arr, opened)
        .then(res => {
          // 刷新表格
          if (msg === '开通') {
            this.visible = false
            this.confirmLoading = false
            this.$refs.createModal.clear()
          } else {
            this.del.visible = false
            this.del.confirmLoading = false
            this.selectedRows.splice(0, this.selectedRows.length)
          }
          this.$refs.table.refresh()
          this.$notification.success({
            message: this.noticeTitle,
            description: `${msg}成功`
          })
          this.getUnOpen()
        })
        .catch(() => {
          if (msg === '开通') {
            this.visible = false
            this.confirmLoading = false
          } else {
            this.del.visible = false
            this.del.confirmLoading = false
            this.selectedRows.splice(0, this.selectedRows.length)
          }
          this.$notification.success({
            message: this.noticeTitle,
            description: `开通失败`
          })
        })
    },
    emptyChildren(arr) {
      const newList = arr.map(item => {
        console.log(item, 'item')
        if (item.fullname.indexOf('重庆市') !== -1) {
          item.name = '重庆市'
        }
        const { children, ...data } = item
        return data
      })
      return newList
    }
  }
}
</script>
