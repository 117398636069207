<template>
  <a-modal
    title="开通城市"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :focus="true"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="城市名称">
          <find-citys ref="selectCity" :opened="false" @emitValue="getValue" :resultType="'id'"></find-citys>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import FindCitys from './FindCitys'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    FindCitys
  },
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  methods: {
    getValue(data) {
      this.$emit('emitResult', data)
    },
    clear() {
      this.$refs.selectCity.clear()
    }
  }
}
</script>
