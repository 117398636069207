<template>
  <a-select
    allow-clear
    mode="multiple"
    label-in-value
    placeholder="选择城市"
    :value="selectedItems"
    style="width: 100%"
    @change="handleChange"
    option-label-prop="label"
  >
    <a-select-option v-for="item in options" :key="item.value" :value="item.value" :label="item.name">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  name: 'MCitySelect',
  data() {
    return {
      selectedItems: []
    }
  },
  computed: {
    options() {
      const data = this.$store.state.city.open
      return this.emptyChildren(data).map(item => {
        return {
          name: item.name,
          value: `${item.name},${item.id}`
        }
      })
    }
  },
  methods: {
    handleChange(selectedItems) {
      this.selectedItems = selectedItems
      const selected = selectedItems.map(item => {
        return item.key.split(',')[1]
      })
      this.$emit('emitValue', selected)
    },
    emptyChildren(arr) {
      const newList = arr.map(item => {
        if (item.fullname.indexOf('重庆市') !== -1) {
          item.name = '重庆市'
        }
        if (item.fullname.indexOf('天津市') !== -1) {
          item.name = '天津市'
        }
        if (item.fullname.indexOf('北京市') !== -1) {
          item.name = '北京市'
        }
        const { children, ...data } = item
        return data
      })
      return newList
    },
    clear() {
      this.selectedItems = []
    }
  }
}
</script>
